const {
  singlePropertyType,
  stateSlug,
  staticHomeRoutes,
  refinements,
} = require('./const')

// eslint-disable-next-line require-await
module.exports = async function redirects() {
  return [
    {
      source: `/:route(${staticHomeRoutes}/?)`,
      destination: '/',
      permanent: true,
    },
    {
      source: '/:route(homes-for-rent/?)',
      destination: '/houses-for-rent',
      permanent: true,
    },
    {
      source: '/:route(moving-center/checklist/?)',
      destination: '/blog/the-ultimate-checklist-for-the-best-move-ever',
      permanent: true,
    },
    {
      source: '/:route(moving-center/tips/?)',
      destination: '/blog/the-rent-com-game-plan',
      permanent: true,
    },
    {
      source:
        '/:route(moving-center/moving-information/how-to-select-a-moving-company/?)',
      destination: '/blog/what-you-should-know-about-hiring-movers',
      permanent: true,
    },
    {
      source: '/:route(careers/jobs/?)',
      destination: 'https://solutions.rent.com/careers/',
      permanent: true,
    },
    {
      source: `/:route(cat-friendly-apartments|dog-friendly-apartments/?)`,
      destination: '/pet-friendly-apartments',
      permanent: true,
    },
    {
      source: '/:route(cheap-rental/?)',
      destination: '/cheap-apartments-for-rent',
      permanent: true,
    },
    {
      source: '/:route(company/press/contacts/?)',
      destination: 'https://solutions.rent.com/contact/',
      permanent: true,
    },
    {
      source: '/:route(company/contact/renter/?)',
      destination: 'https://solutions.rent.com/contact/',
      permanent: true,
    },
    {
      source: '/:route(company/contact/property/?)',
      destination: 'https://solutions.rent.com/contact/',
      permanent: true,
    },
    {
      source: '/:route(company/contact/business/?)',
      destination: 'https://solutions.rent.com/contact/',
      permanent: true,
    },
    {
      source: '/:route(company/company-overview/?)',
      destination: 'https://solutions.rent.com/about/',
      permanent: true,
    },
    {
      source: '/:route(company/about-us/?)',
      destination: 'https://solutions.rent.com/about/',
      permanent: true,
    },
    {
      source: '/:route(company/privacy-full/?)',
      destination: 'https://solutions.rent.com/privacy-policy/',
      permanent: true,
    },
    {
      source: '/:route(company/privacy-do-not-track/?)',
      destination: 'https://solutions.rent.com/privacy-policy/',
      permanent: true,
    },
    {
      source: '/:route(company/cookie-policy/?)',
      destination: 'https://solutions.rent.com/privacy-policy/',
      permanent: true,
    },
    {
      source: '/:route(company/legal/equal/?)',
      destination:
        'https://solutions.rent.com/legal/equal-housing-opportunity/',
      permanent: true,
    },
    {
      source: '/:route(company/legal/termsofservice/?)',
      destination: 'https://solutions.rent.com/terms-of-use/',
      permanent: true,
    },
    {
      source: '/manage',
      destination: '/list',
      permanent: true,
    },
    {
      source: '/account',
      destination: '/profile',
      permanent: true,
    },
    {
      // /rentals/texas -> /texas
      source: `/rentals/:state(${stateSlug})`,
      destination: '/:state',
      permanent: true,
    },
    {
      // /texas/12345-67 -> /texas
      source: `/:state(${stateSlug})/:zip(\\d{1,}-\\d{1,})`,
      destination: '/:state',
      permanent: true,
    },
    {
      // /local-info/austin-texas -> /texas/austin-apartments
      source: `/local-info/:city-:state(${stateSlug})`,
      destination: '/:state/:city-apartments',
      permanent: true,
    },
    {
      // /texas/austin/apartments -> /texas/austin-apartments
      source: `/:state/:location/${singlePropertyType}`,
      destination: `/:state/:location-${singlePropertyType}`,
      permanent: true,
    },
    // /apartment-rentals/home-rentals -> /houses-for-rent
    ...Object.keys(refinements).map((source) => ({
      source: `/:route(apartment-rentals|rental-search)/:refinement(${source}/?)`,
      destination: `/${refinements[source] || source}`,
      permanent: true,
    })),
    {
      // /texas/austin-apartments/property-reviews-5-12345678 -> /texas/austin-apartments/property-4-12345678?display=reviews
      source: '/:routeA([a-z-]+\\/[a-z-]+\\/.*)-reviews-5-:routeB(\\d{1,})',
      destination: '/:routeA-4-:routeB?display=reviews',
      permanent: true,
    },
    // Redirect needed for home-page, property-type-tab
    {
      source: `/zip-:zipCode/${singlePropertyType}`,
      destination: `/zip-:zipCode-${singlePropertyType}`,
      permanent: true,
    },
    // Redirect needed for home-page, property-type-tab
    {
      source: `/:state/counties/:county/${singlePropertyType}`,
      destination: `/:state/counties/:county-${singlePropertyType}`,
      permanent: true,
    },
    // Redirect needed for home-page, property-type-tab
    {
      source: `/:state/:location/schools/:school/${singlePropertyType}`,
      destination: `/:state/:location/schools/:school-${singlePropertyType}`,
      permanent: true,
    },
    // Redirect needed for home-page, property-type-tab
    {
      source: `/:state/schools/:location-:schoolDistrict/${singlePropertyType}`,
      destination: `/:state/schools/:location-:schoolDistrict-${singlePropertyType}`,
      permanent: true,
    },
    // Redirect needed for home-page, property-type-tab
    {
      source: `/:state/:location/${singlePropertyType}/:hood-neighborhood`,
      destination: `/:state/:location-${singlePropertyType}/:hood-neighborhood`,
      permanent: true,
    },
    // Redirect needed for home-page, property-type-tab
    {
      source: `/p/:state/:location/:poi/${singlePropertyType}`,
      destination: `/p/:state/:location-${singlePropertyType}/:poi`,
      permanent: true,
    },
    // Redirect needed for misspelled city name: Lexington, SC
    // this redirect is temporary until cloudflare is able to take over on the redirect
    {
      source: `/south-carolina/lexingron-${singlePropertyType}`,
      destination: `/south-carolina/lexington-${singlePropertyType}`,
      permanent: true,
    },
    // Redirect "CoreLogic PDPs" to SRP
    // https://rentpath.atlassian.net/browse/WEB-9702
    {
      source:
        '/:state/:city-:propertyTypes(apartments|condos|townhouses|houses)/:propertyName(.*)-4-:listingId(c.*)',
      destination: '/:state/:city-:propertyTypes',
      permanent: true,
    },
    {
      source: '/:route(houses-for-rent/?)',
      destination: '/near-me/houses',
      permanent: true,
    },
    {
      source: '/:route(townhomes-for-rent/?)',
      destination: '/near-me/townhomes',
      permanent: true,
    },
    {
      source: '/:route(cheap-apartments-for-rent/?)',
      destination: '/near-me/cheap-apartments',
      permanent: true,
    },
    {
      source: '/:route(luxury-apartments/?)',
      destination: '/near-me/luxury-apartments',
      permanent: true,
    },
    {
      source: '/:route(pet-friendly-apartments/?)',
      destination: '/near-me/pet-friendly-apartments',
      permanent: true,
    },
    {
      source: '/:route(1-bedroom-apartment/?)',
      destination: '/near-me/1-bedroom-apartments',
      permanent: true,
    },
    {
      source: '/:route(2-bedroom-apartment/?)',
      destination: '/near-me/2-bedroom-apartments',
      permanent: true,
    },
    {
      source: '/:route(3-bedroom-apartment/?)',
      destination: '/near-me/3-bedroom-apartments',
      permanent: true,
    },
    {
      source: '/:route(studio-apartment/?)',
      destination: '/near-me/studio-apartments',
      permanent: true,
    },
    {
      source: '/:route(condos-for-rent/?)',
      destination: '/near-me/condos',
      permanent: true,
    },
  ]
}
