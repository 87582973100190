module.exports = {
  refinements: {
    'cheap-apartments': 'cheap-apartments-for-rent',
    'duplex-for-rent': 'houses-for-rent',
    'home-rentals': 'houses-for-rent',
    'homes-for-rent': 'houses-for-rent',
    'real-estate-rental': 'rental-properties',
  },
  singlePropertyType: ':propertyTypes(apartments|condos|houses|townhouses)',
  stateSlug:
    'alabama|alaska|arizona|arkansas|california|colorado|connecticut|delaware|district-of-columbia|florida|georgia|hawaii|idaho|illinois|indiana|iowa|kansas|kentucky|louisiana|maine|maryland|massachusetts|michigan|minnesota|mississippi|missouri|montana|nebraska|nevada|new-hampshire|new-jersey|new-mexico|new-york|north-carolina|north-dakota|ohio|oklahoma|oregon|pennsylvania|rhode-island|south-carolina|south-dakota|tennessee|texas|utah|vermont|virginia|washington|west-virginia|wisconsin|wyoming',
  staticHomeRoutes:
    'about-our-listings|apartment-directory|apartment-guide|apartment-ratings|apartment-reviews|apartments-by-city|apartments-for-rent|enter|housing-near-military-base|off-campus-housing|move-in-specials|rental-properties|rentals-by-type|search-by-state|service/reward',
  sitemapType: ':sitemapType(popular|new|other)',
}
