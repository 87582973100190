/**
 * @type {import('next').NextConfig}
 */
module.exports = {
  trailingSlash: false,
  distDir: 'build/rent',
  headers: require('./headers'),
  rewrites: require('./rewrites'),
  redirects: require('./redirects'),
}
