import { Host } from '../../../config/host'

import type { Credentials } from '../../../features/tagging/tagging.types'

// tagging profile
export const profile: Record<Host, string> = {
  [Host.rce]: 'rent',
  [Host.prod]: 'rent',
  [Host.re]: 'qa',
  [Host.qa]: 'qa',
  [Host.ci]: 'dev',
  [Host.local]: 'dev',
} as const

// tagging subprofile
export const subprofile: Record<Host, string> = {
  [Host.rce]: 'www.rent.com',
  [Host.prod]: 'www.rent.com',
  [Host.re]: 'other',
  [Host.qa]: 'www.qa.rent.com',
  [Host.ci]: 'www.ci.rent.com',
  [Host.local]: 'other',
}

// gtm setup options
export const credentials: Credentials = {
  standard: {
    id: 'GTM-TBLQN7C',
    auth: {
      [Host.prod]: 'r5H4w-seTNSz8jy9Q8errA',
      [Host.rce]: '1xrkJlFHea9aPT93Sjeozg',
      [Host.qa]: 't7hwel3232gfKkT_pprFVw',
      [Host.re]: 't7hwel3232gfKkT_pprFVw',
      default: 'Fcw3S4Uy2Rjhht3T0yJsJQ',
    },
    preview: {
      [Host.prod]: 'env-2',
      [Host.rce]: 'env-899',
      [Host.qa]: 'env-5',
      [Host.re]: 'env-5',
      default: 'env-7',
    },
  },
  gdpr: {
    id: 'GTM-WCHZF7F',
    auth: {
      [Host.prod]: 'R5soAiloHMjiRhyOkM-KfA',
      [Host.qa]: 'iv3YATU6ecMLQqUtLwAXNQ',
      [Host.re]: 'iv3YATU6ecMLQqUtLwAXNQ',
      default: 'RtGAdZNHwn62--HcoB3QCQ',
    },
    preview: {
      [Host.prod]: 'env-2',
      [Host.qa]: 'env-95',
      [Host.re]: 'env-95',
      default: 'env-97',
    },
  },
}

export const measurementIds = {
  qa: '3MW7YD9W38',
  prod: 'GZ9G82Y2EZ',
} as const

// GA Session ID map by host
export const gaSessionIdMap: Record<Host, string> = {
  [Host.rce]: measurementIds.prod,
  [Host.prod]: measurementIds.prod,
  [Host.re]: measurementIds.qa,
  [Host.qa]: measurementIds.qa,
  [Host.ci]: measurementIds.qa,
  [Host.local]: measurementIds.qa,
}
